.Page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.header, .footer {
  background-color: var(--light-bg);
  border-top: 1px solid var(--light-border);
  border-bottom: 1px solid var(--light-border);
  min-height: 50px;
  display: inline-flex;
}

.header {
  h1 {
    color: var(--primary-text-color);
  }
}

.footer {
  text-align: center;
}

.leftNav {
  background-color: var(--light-bg);
  width: 250px;
  border-right: 1px solid var(--light-border);
}

.main-container {
  display: flex;
  flex: 1;
}

.main {
  padding: 20px;
  flex: 1;
  background: #ffffff;
}

h1 {
  font-size: 1.5rem;
  margin: 0;
}

a {
  color: var(--nav-color);
  text-decoration: none;
}
