:root {
  // Styling for the navigation
  --primary-text-color: #353535;
  --light-bg: #f8f8f8;
  --light-border: #e7e7e7;
  --nav-color: rgb(0, 23, 85);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  color: var(--primary-text-color);
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}