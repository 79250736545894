.leftNav {
    ul, li {
        list-style-type: none;
    }

    .nav-link {
        color: var(--nav-color);
        &.active {
            font-weight: bold;
            border-right: 4px solid var(--nav-color);
        }
    }
}
